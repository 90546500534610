
.broadcastManagePage{
  max-width: 960px;
}












