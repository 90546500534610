
.pageTransactionsList{
  .totalTransaction{
    border: 2px solid #f05f42;
    display: inline-block;
    padding: 5px 20px;
    font-weight: 500;
    font-size: 16px;
    color: #f05f42;
  }
}