
.jobTypeManage{
  max-width: 960px;
  .btnFieldAddNew{
    text-align: right;
    margin-bottom: 20px;
  }
}


.modalJobType{
  .formBtnGroup{
    text-align: right;
    button{
      margin-left: 15px;
    }

  }
}