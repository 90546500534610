.tableUserPrice{
  .wrapBtnAddPrice{
    text-align: right;
  }
}
.modalCreatePrice{
  .formBtnGroup{
    text-align: right;
    button{
      margin-left: 15px;
    }
  }
}
