
.ant-layout.privateRouteMain{
  background: #fff;
  padding-bottom: 20px;
}

.header {
  border-bottom: 1px solid #ddd;
  &.ant-layout-header {
    padding: 0;
    line-height: normal;
    background: #fff;
    height: 32px;
  }
  .userPopover {
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .btnEmployeeName {
      padding-right: 0;
      font-size: 14px;
      font-weight: 600;
    }
  }
}

.privatePageMain {
  padding-left: 30px;
  padding-right: 30px;
}

.dateFromTo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .flexCol {
    width: calc(50% - 30px);
    .ant-picker {
      width: 100%;
    }
  }
}

.popoverUserProfile{
  min-width: 150px;
  ul{
    padding: 0;
    margin: 0;
    list-style: none;
    li{
      padding: 5px 0;
    }
  }
}
