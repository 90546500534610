@import '../../styles/variable';


.mainSidebar{

  .logo{
    padding: 5px 20px;
  }
}


















